<template>
	<router-view/>
</template>

<script>
export default {
	name: "HomeView"
};
</script>

<style lang="scss">

</style>
